import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	Input,
	OnInit,
	OnDestroy
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { LoggerService } from '@services/logger/logger.service';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-inline-errors',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styles: [
		`
			:host:empty {
				display: none;
			}
		`
	],
	template: `
		<div
			class="text-danger"
			*ngIf="fieldLocal?.errors && fieldLocal.dirty && fieldLocal.invalid"
		>
			<p class="m-0" *ngFor="let e of errorKeys">
				{{ getErrorMessage(e) }}
			</p>
		</div>
	`
})
export class InlineErrorsComponent implements OnInit, OnDestroy {
	formLocal: FormGroup;
	fieldLocal: FormControl;
	fieldLocalName: string;
	messagesLocal: {};
	public errorKeys = null;
	private subscriptions: Subscription[] = [];
	@Input()
	set form(form: FormGroup) {
		if (!form) return;
		this.formLocal = form;
	}

	@Input()
	set fieldName(name) {
		this.fieldLocalName = name;
	}

	@Input()
	set errorMessages(err: {}) {
		this.messagesLocal = err;
	}

	constructor(
		private log: LoggerService,
		private cd: ChangeDetectorRef,
		private translate: TranslateService
	) {}

	ngOnInit() {
		if (!this.formLocal || !this.fieldLocalName) return;
		this.cd.detach();
		this.cd.detectChanges();
		this.fieldLocal = <FormControl>this.formLocal.get(this.fieldLocalName);
		this.subscriptions.push(
			this.fieldLocal.valueChanges.subscribe(res => {
				this.errorKeys = Object.keys(this.fieldLocal.errors || {});
				this.cd.detectChanges();
			})
		);
	}

	getErrorMessage(key) {
		if (
			_.has(this.messagesLocal, this.fieldLocalName) &&
			!_.has(this.messagesLocal[this.fieldLocalName], key)
		) {
			this.log.log(`no phrase for this error: ${key}`);
		}
		return _.has(this.messagesLocal, this.fieldLocalName)
			? _.get(this.messagesLocal, `${this.fieldLocalName}.${key}`)
			: this.translate.instant(`inlineErrors.${key}`);
	}

	ngOnDestroy() {
		this.cd.detach();
		this.subscriptions.forEach((subscription: Subscription) =>
			subscription.unsubscribe()
		);
	}
}
