import { Injectable } from '@angular/core';
import { HttpAuth } from '../../_services/httpauth/http-auth.service';
import { LoginUtils } from '../../_services/loginutils/loginutils.service';
import * as _ from 'lodash';
import { ChangePasswordDto } from '@models/changePasswordDto';

@Injectable()
export class AccountService {
	constructor(private http: HttpAuth, private loginutils: LoginUtils) {}

	get profile() {
		return this.loginutils.user;
	}

	getProfile() {
		return this.http.get('/user/profile');
	}

	submitAvatar(file) {
		const fd = new FormData();
		// fd.append('dto', new Blob([JSON.stringify(data)], { type: 'application/json' }));
		if (!_.isNull(file)) {
			fd.append('avatar', file);
		}

		return this.http.post('/user/upload-avatar', fd);
	}

	changePassword(changePasswordDto: ChangePasswordDto) {
		return this.http.post<ChangePasswordDto>(
			'/change-password',
			changePasswordDto
		);
	}
}
