import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { LoggerService } from '@services/logger/logger.service';
import { ValidatorFn, FormControl } from '@angular/forms';

@Injectable()
export class UtilsService {
	constructor(private log: LoggerService) {}

	columnsForDatatables(columns: any[] = []) {
		if (!columns.length) return columns;
		return columns.map(this.columnsCreator);
	}

	private columnsCreator(item) {
		return typeof item === 'string'
			? { name: item }
			: _.has(item, 'name')
			? item
			: { name: 'no-name' };
	}

	listFromEnum(enm, onlyKeys = true) {
		this.log.log('meh', enm);
		return onlyKeys
			? Object.keys(enm).filter(k => typeof enm[k as any] === 'number')
			: Object.keys(enm)
					.filter(k => typeof enm[k as any] === 'number')
					.map(item => ({ [enm[item]]: item }));
	}

	public emailValidator: ValidatorFn = (control: FormControl) => {
		const re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[a-zA-Z]{2,})$/i;

		if (control.value && !re.test(control.value)) {
			return {
				emailValidator: { value: 'value is invalid' }
			};
		}
		return null;
	};
	public matchOtherValidator(otherControlName: string) {
		let thisControl: FormControl;
		let otherControl: FormControl;

		return function matchOtherValidate(control: FormControl) {
			if (!control.parent) {
				return null;
			}

			// Initializing the validator.
			if (!thisControl) {
				thisControl = control;
				otherControl = control.parent.get(otherControlName) as FormControl;
				if (!otherControl) {
					throw new Error(
						'matchOtherValidator(): other control is not found in parent group'
					);
				}
				otherControl.valueChanges.subscribe(() => {
					thisControl.updateValueAndValidity();
				});
			}

			if (!otherControl) {
				return null;
			}

			if (otherControl.value !== thisControl.value) {
				return {
					matchOther: true
				};
			}
		};
	}
}

export interface DTColumn {
	name: string;
}

export interface Pageable {
	page?: number;
	size?: number;
	sort?: string;
	ascending?: boolean;
}

export class PageableSort {
	readonly property: string;
	readonly ascending: boolean;
	readonly requestParam: string;

	constructor(property: string, ascending = true) {
		this.property = property;
		this.ascending = ascending;
		this.requestParam = property + ',' + (ascending ? 'asc' : 'desc');
	}
}
