import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { LayoutService } from '@services/layout/layout.service';

@Component({
	selector: 'app-layout',
	templateUrl: './layout.component.html',
	styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit, OnDestroy {
	mobileQuery: MediaQueryList;

	private mobileQueryListener: () => void;

	constructor(
		private changeDetectorRef: ChangeDetectorRef,
		private media: MediaMatcher,
		private layoutService: LayoutService
	) {}

	ngOnInit() {
		this.mobileQuery = this.media.matchMedia('(max-width: 600px)');
		this.mobileQueryListener = () => this.changeDetectorRef.detectChanges();
		this.mobileQuery.addListener(this.mobileQueryListener);
	}

	ngOnDestroy(): void {
		this.mobileQuery.removeListener(this.mobileQueryListener);
	}

	get showMenu() {
		return this.layoutService.showMenu;
	}
}
