import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { MediaMatcher } from '@angular/cdk/layout';

@Injectable({
	providedIn: 'root'
})
export class LayoutService {
	mobileQuery: MediaQueryList;
	menu = new Subject();

	showMenu: boolean;

	constructor(media: MediaMatcher) {
		this.mobileQuery = media.matchMedia('(max-width: 600px)');
		this.showMenu = !this.mobileQuery.matches;

		this.menu.subscribe(value => (this.showMenu = !this.showMenu));
	}

	toggleMenu() {
		this.menu.next();
	}
}
