import { DropdownMenuComponent } from './../../_components/drop-menu/drop-menu.component';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormTextFieldComponent } from '../../_components/form-text-field/form-text-field.component';
import { FormFieldComponent } from '../../_components/form-field/form-field.component';
import { FileUploaderComponent } from '../../_components/file-uploader/file-uploader.component';
import { InlineErrorsComponent } from '../../_components/inline-errors/inline-errors.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ServerErrorsComponent } from '../../_components/server-errors/server-errors.component';
import { AgmCoreModule } from '@agm/core';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ConfirmModalComponent } from '../../_components/confirm-modal/confirm-modal.component';
import { LocaleDatePipe } from '../../_pipes/locale-date.pipe';
import { LocalCurrencyPipe } from '../../_pipes/local-currency.pipe';
import { PagerComponent } from '@components/pager/pager.component';
import { LoggerService } from '@services/logger/logger.service';
import { DateAgoPipe } from '@pipes/date-ago.pipe';
import { PageHeaderComponent } from '@components/page-header/page-header.component';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AppHeaderComponent } from '@components/applications/app-header/app-header.component';
import { MatBadgeModule } from '@angular/material/badge';
import { ModalComponent } from '@components/modal/modal.component';
import { ModalService } from '@services/utils/modal.service';
import { CreateMessageTemplateComponent } from '../../routes/settings/settings-message-template/create-message-template/create-message-template.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { PageLayoutComponent } from '@components/page-layout/page-layout.component';
import { MatSelectModule } from '@angular/material/select';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { BadgePipe } from '@pipes/badge.pipe';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatExpansionModule } from '@angular/material/expansion';
import { DatePipeDecorator } from '@decorators/date-pipe.decorator';
import { AutofocusDirective } from '../../_directives/autofocus.directive';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		RouterModule,
		ReactiveFormsModule,
		TranslateModule,
		NgbModule,
		NgxDatatableModule,
		NgSelectModule,
		InfiniteScrollModule,
		MatIconModule,
		MatInputModule,
		MatCheckboxModule,
		MatButtonModule,
		MatMenuModule,
		MatBadgeModule,
		MatProgressSpinnerModule,
		MatSelectModule,
		MatBottomSheetModule,
		MatToolbarModule,
		MatSidenavModule,
		MatExpansionModule,
		MatSlideToggleModule,
		AgmCoreModule.forRoot({
			apiKey: 'AIzaSyD-79vx4QKpzkhMJvklsT18m5a3JnrcNps'
		})
	],
	entryComponents: [ConfirmModalComponent],
	exports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		TranslateModule,
		NgbModule,
		NgxDatatableModule,
		ServerErrorsComponent,
		FormTextFieldComponent,
		FormFieldComponent,
		FileUploaderComponent,
		NgSelectModule,
		AgmCoreModule,
		InfiniteScrollModule,
		DropdownMenuComponent,
		ConfirmModalComponent,
		OverlayModule,
		MatIconModule,
		MatInputModule,
		MatCheckboxModule,
		MatButtonModule,
		MatMenuModule,
		MatBadgeModule,
		MatProgressSpinnerModule,
		MatSelectModule,
		MatBottomSheetModule,
		MatToolbarModule,
		MatSidenavModule,
		MatExpansionModule,
		LocaleDatePipe,
		LocalCurrencyPipe,
		DateAgoPipe,
		PagerComponent,
		PageHeaderComponent,
		PageLayoutComponent,
		AppHeaderComponent,
		ModalComponent,
		BadgePipe,
		DatePipeDecorator,
		AutofocusDirective,
		MatSlideToggleModule
	],
	providers: [LoggerService, ModalService],
	declarations: [
		ServerErrorsComponent,
		FormTextFieldComponent,
		FormFieldComponent,
		PageHeaderComponent,
		PageLayoutComponent,
		FileUploaderComponent,
		InlineErrorsComponent,
		DropdownMenuComponent,
		ConfirmModalComponent,
		LocaleDatePipe,
		LocalCurrencyPipe,
		DateAgoPipe,
		PagerComponent,
		AppHeaderComponent,
		ModalComponent,
		CreateMessageTemplateComponent,
		BadgePipe,
		DatePipeDecorator,
		AutofocusDirective
	]
})
export class SharedModule {
	static forRoot(): ModuleWithProviders {
		return {
			ngModule: SharedModule
		};
	}
}
