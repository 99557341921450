import { Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { from, Observable, of } from 'rxjs';
import {
	ConfirmModalComponent,
	ConfirmType
} from '@components/confirm-modal/confirm-modal.component';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ModalService {
	constructor(private modalService: NgbModal) {}

	confirm(
		type?: ConfirmType,
		settings?: ConfirmModalSettings
	): Observable<boolean> {
		const instance = this.modalService.open(ConfirmModalComponent);
		instance.componentInstance.type = type || ConfirmType.DEFAULT;

		if (settings !== undefined) {
			const { title, description } = settings;
			instance.componentInstance.title = title;
			instance.componentInstance.description = description;
		}

		return from(instance.result).pipe(catchError(_ => of()));
	}

	open<T>(
		template: any,
		data?: any,
		options: NgbModalOptions = {}
	): Observable<T> {
		const instance = this.modalService.open(template, options);
		if (data) {
			instance.componentInstance.data = data;
		}
		return from(instance.result).pipe(catchError(err => of()));
	}
}

interface ConfirmModalSettings {
	title?: string;
	description?: string;
}
