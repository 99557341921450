import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
	selector: 'app-form-field',
	template: `
		<ng-content></ng-content>
		<app-inline-errors
			[form]="formP"
			[fieldName]="fieldP"
			[errorMessages]="inlineErrorsP"
		></app-inline-errors>
		<app-server-errors
			[errors]="serverErrorsP"
			[fieldName]="fieldP"
			*ngIf="serverErrorsP"
		></app-server-errors>
	`
})
export class FormFieldComponent {
	public serverErrorsP: {} = null;
	public fieldP: string = null;
	public formP: FormGroup;
	public inlineErrorsP: {};

	@Input()
	set form(form: FormGroup) {
		if (!form) return;
		this.formP = form;
	}
	@Input()
	set serverErrors(errors: any) {
		if (!errors) return;
		this.serverErrorsP = errors;
	}
	@Input()
	set field(field: string) {
		if (!field) return;
		this.fieldP = field;
	}
	@Input()
	set inlineErrors(err: {}) {
		if (!err) return;
		this.inlineErrorsP = err;
	}
}
