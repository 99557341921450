import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
	name: 'dateAgo',
	pure: true
})
export class DateAgoPipe implements PipeTransform {
	constructor(private readonly translate: TranslateService) {}

	transform(value: number | string | Date): any {
		if (value) {
			const seconds = Math.floor((+new Date() - +new Date(value)) / 1000);
			if (seconds < 29)
				// less than 30 seconds ago will show as 'Just now'
				return this.translate.instant('dates.just-now');
			const intervals = {
				year: 31536000,
				month: 2592000,
				week: 604800,
				day: 86400,
				hour: 3600,
				minute: 60,
				second: 1
			};
			let counter;
			for (const timeInterval in intervals) {
				if (intervals.hasOwnProperty(timeInterval)) {
					counter = Math.floor(seconds / intervals[timeInterval]);
					if (counter > 0) {
						return this.createTranslation(counter, timeInterval);
					}
				}
			}
		}
		return value;
	}

	private createTranslation(counter: number, timeInterval: string): string {
		const translationKey = this.determineTranslationKey(counter);
		const translateParams = {
			interval: this.createIntervalTranslation(timeInterval),
			counter
		};
		return this.translate.instant(translationKey, translateParams);
	}

	private determineTranslationKey(counter: number): string {
		const isPlural = counter > 1;
		return isPlural ? 'dates.time-ago.plural' : 'dates.time-ago.singular';
	}

	private createIntervalTranslation(interval: string): string {
		return this.translate.instant(`dates.intervals.${interval}`);
	}
}
