import { Component } from '@angular/core';
import { PasswordRecoveryFormComponent } from '../password-recovery-form/password-recovery-form.component';

@Component({
	selector: 'app-reset-password-form',
	templateUrl: './reset-password-form.component.html',
	styleUrls: ['./reset-password-form.component.scss']
})
export class ResetPasswordFormComponent extends PasswordRecoveryFormComponent {
	constructor() {
		super();
	}
}
