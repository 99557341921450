import { Component, TemplateRef, ViewChild } from '@angular/core';
import { LogoutService } from '@services/loginutils/logout.service';
import { environment } from '../../../environments/environment';
import { LoginUtils } from '@services/loginutils/loginutils.service';
import { ModalService } from '@services/utils/modal.service';
import { ChangePasswordComponent } from '../../routes/account/change-password/change-password.component';

@Component({
	selector: 'app-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {
	version = environment.version;

	constructor(
		private logoutService: LogoutService,
		public userData: LoginUtils,
		private modalService: ModalService
	) {}

	@ViewChild('menu') menu: TemplateRef<any>;

	openModal(): void {
		this.modalService.open(ChangePasswordComponent).subscribe(value => {
			console.log(value);
		});
	}

	confirmLogout() {
		this.modalService.confirm().subscribe(value => this.logoutService.logout());
	}
}
