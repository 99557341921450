import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
	name: 'date'
})
export class DatePipeDecorator extends DatePipe implements PipeTransform {
	private static readonly DEFAULT_DATE_FORMAT = 'MM/dd/yyyy';

	constructor(@Inject(LOCALE_ID) locale: string) {
		super(locale);
	}

	transform(
		value: number | string | Date,
		format = DatePipeDecorator.DEFAULT_DATE_FORMAT
	): string {
		return super.transform(value, format);
	}
}
