import { Component, EventEmitter, Output } from '@angular/core';

@Component({
	selector: 'app-modal',
	template: `
		<div class="card">
			<h5 class="card-header">
				<ng-content select="[slot=title]"></ng-content>
				<button
					type="button"
					class="close"
					aria-label="Close"
					(click)="close.emit(true)"
				>
					<span aria-hidden="true">&times;</span>
				</button>
			</h5>
			<div class="card-body">
				<div class="card-text">
					<ng-content></ng-content>
				</div>
			</div>
			<div class="card-footer text-center">
				<ng-content select="[slot=footer]"></ng-content>
			</div>
		</div>
	`
})
export class ModalComponent {
	@Output() close: EventEmitter<boolean> = new EventEmitter();
}
