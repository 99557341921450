import { Component, Input } from '@angular/core';
import { LayoutService } from '@services/layout/layout.service';
import { NavigationService } from '@services/navigation/navigation.service';

@Component({
	selector: 'app-page-header',
	templateUrl: './page-header.component.html',
	styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent {
	@Input() title: string;

	constructor(
		private readonly layoutService: LayoutService,
		private readonly navigationService: NavigationService
	) {}

	toggleMenu(): void {
		this.layoutService.toggleMenu();
	}

	navigateBack(): void {
		this.navigationService.navigateBack();
	}
}
