import { Routes } from '@angular/router';
import { LayoutComponent } from '@components/layout/layout.component';
import { LoginComponent } from './routes/pages/login/login.component';
import { IsLoggedGuard } from '@guards/is-logged.guard';
import { Page403Component } from './routes/pages/page403/page403.component';
import { IsAdminGuard } from '@guards/is-admin.guard';

export const routes: Routes = [
	{
		path: '',
		component: LayoutComponent,
		canActivate: [IsLoggedGuard],
		children: [
			{
				path: '',
				redirectTo: 'applications',
				pathMatch: 'full'
			},
			{
				path: 'account',
				loadChildren: './../../routes/account/account.module#AccountModule'
			},
			{
				path: 'applications',
				loadChildren:
					'./../../routes/applications/applications.module#ApplicationsModule'
			},
			{
				path: 'back-office',
				canActivate: [IsAdminGuard],
				loadChildren:
					'./../../routes/back-office/back-office.module#BackOfficeModule'
			},
			{
				path: 'messages',
				loadChildren: './../../routes/messages/messages.module#MessagesModule'
			},
			{
				path: 'settings',
				loadChildren: './../../routes/settings/settings.module#SettingsModule'
			}
		]
	},
	{ path: 'login', component: LoginComponent },
	{ path: '403', component: Page403Component },
	{ path: 'set-new-password/:token', component: LoginComponent },
	{ path: '**', redirectTo: 'home' }
];
export interface Menus {
	path: string;
	slug: string;
	visible?: boolean;
	children?: Menus[];
}
export const menus: Menus[] = [
	{ path: '/applications', slug: 'applications' },
	{ path: '/users', slug: 'users' }
];
