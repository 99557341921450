import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'badge'
})
export class BadgePipe implements PipeTransform {
	transform(value: any, args?: any): string {
		return Number(value) >= 10 ? '9+' : value;
	}
}
