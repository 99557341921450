import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UtilsService } from '@services/utils/utils.service';
import { AccountService } from '../account.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-change-password',
	templateUrl: './change-password.component.html',
	styleUrls: ['./change-password.component.scss'],
	providers: [AccountService]
})
export class ChangePasswordComponent implements OnInit, OnDestroy {
	private readonly subscriptions: Subscription[] = [];
	form: FormGroup;

	constructor(
		private fb: FormBuilder,
		private utils: UtilsService,
		private accountService: AccountService,
		private toastrService: ToastrService,
		private translateService: TranslateService,
		private activeModal: NgbActiveModal
	) {}

	ngOnInit() {
		this.form = this.fb.group({
			oldPassword: [null, Validators.required],
			newPassword: [null, Validators.required],
			confirmPassword: [
				null,
				[Validators.required, this.utils.matchOtherValidator('newPassword')]
			]
		});
	}

	submit() {
		if (this.form.invalid) {
			return;
		}
		const { oldPassword, newPassword } = this.form.value;
		const handleSuccess = () => {
			this.toastrService.success(
				this.translateService.instant('backOffice.passwordChanged'),
				this.translateService.instant('toastr.success')
			);
			this.close();
		};
		this.subscriptions.push(
			this.accountService
				.changePassword({ oldPassword, newPassword })
				.subscribe(handleSuccess)
		);
	}

	close() {
		this.activeModal.dismiss();
	}

	ngOnDestroy() {
		this.subscriptions.forEach((subscription: Subscription) =>
			subscription.unsubscribe()
		);
	}
}
