import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-server-errors',
	template: `
		<div *ngIf="customError" class="text-danger">
			<span> {{ customError | translate }} </span>
		</div>
	`
})
export class ServerErrorsComponent {
	public customError: string;
	private workingField: string;

	@Input()
	set fieldName(field: string) {
		this.workingField = field;
	}
	@Input()
	set errors(errors: any) {
		const p = e => e.field === this.workingField;
		this.customError = errors && errors.find(p) ? errors.find(p).message : null;
	}
}
