import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { SharedModule } from '../../_modules/shared/shared.module';
import { RouterModule } from '@angular/router';
import { LoginService } from './login/login.service';
import { Page403Component } from './page403/page403.component';
import { ForgotPasswordComponent } from './login/forgot-password/forgot-password.component';
import { PasswordRecoveryFormComponent } from './login/password-recovery-form/password-recovery-form.component';
import { ResetPasswordFormComponent } from './login/reset-password-form/reset-password-form.component';

@NgModule({
	imports: [CommonModule, SharedModule, RouterModule],
	declarations: [
		LoginComponent,
		Page403Component,
		ForgotPasswordComponent,
		PasswordRecoveryFormComponent,
		ResetPasswordFormComponent
	],
	entryComponents: [ForgotPasswordComponent],
	exports: [LoginComponent],
	providers: [LoginService]
})
export class PagesModule {}
