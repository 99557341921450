import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
	selector: 'app-password-recovery-form',
	templateUrl: './password-recovery-form.component.html',
	styleUrls: ['./password-recovery-form.component.scss']
})
export class PasswordRecoveryFormComponent {
	@Input() form: FormGroup;
	@Input() wasFormSuccessfullySent: boolean;
	errors = null;

	@Output() formSubmitted: EventEmitter<unknown> = new EventEmitter();
	@Output() modalClosed: EventEmitter<unknown> = new EventEmitter();

	constructor() {}

	submitForm() {
		this.formSubmitted.emit(null);
	}

	closeModal() {
		this.modalClosed.emit(null);
	}
}
