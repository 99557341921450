import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-confirm-modal-component',
	template: `
		<app-modal (close)="activeModal.dismiss(false)">
			<ng-container slot="title">{{
				title || 'confirmModal.' + type + '.title' | translate
			}}</ng-container>
			<div>
				{{ description || 'confirmModal.' + type + '.description' | translate }}
			</div>
			<ng-container slot="footer">
				<button
					mat-flat-button
					color="warn"
					class="mx-1"
					ngbAutofocus
					(click)="activeModal.close(true)"
				>
					{{ 'confirmModal.' + type + '.confirm' | translate }}
				</button>
				<button
					mat-stroked-button
					class="mx-1"
					(click)="activeModal.dismiss(false)"
				>
					{{ 'confirmModal.' + type + '.cancel' | translate }}
				</button>
			</ng-container>
		</app-modal>
	`
})
export class ConfirmModalComponent {
	@Input() type;
	@Input() title: string;
	@Input() description: string;

	constructor(public activeModal: NgbActiveModal) {}
}

export enum ConfirmType {
	DEFAULT = 'default',
	DANGER = 'danger',
	REMOVE = 'remove',
	CONFIRM = 'confirm'
}
