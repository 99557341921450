import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LoginService } from '../login.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-forgot-password',
	templateUrl: './forgot-password.component.html',
	styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
	@Input() data: FormGroup;
	isPasswordRecoveryForm: boolean;
	wasFormSuccessfullySent = false;
	private subscriptions: Subscription[] = [];

	constructor(
		private activeModal: NgbActiveModal,
		private loginService: LoginService,
		private toastrService: ToastrService,
		private translateService: TranslateService
	) {}

	ngOnInit() {
		this.isPasswordRecoveryForm = !!this.data.get('email');
	}

	submitPasswordRecoveryForm() {
		if (this.data.invalid) {
			return;
		}
		const { email } = this.data.value;
		this.subscriptions.push(
			this.loginService
				.sendRecovery(email)
				.subscribe(() => this.handleSuccess('general.resetEmailSent'))
		);
	}

	submitResetPasswordForm() {
		if (this.data.invalid) {
			return;
		}
		const { newPassword, token } = this.data.value;
		this.subscriptions.push(
			this.loginService
				.sendNewPassword({ newPassword, token })
				.subscribe(() => this.handleSuccess('general.newPasswordSet'))
		);
	}

	close() {
		this.data.reset();
		this.activeModal.dismiss();
	}

	private handleSuccess(translationKey: string) {
		this.toastrService.success(
			this.translateService.instant(translationKey),
			this.translateService.instant('toastr.success')
		);
		this.wasFormSuccessfullySent = true;
	}

	ngOnDestroy() {
		this.subscriptions.forEach((subscription: Subscription) =>
			subscription.unsubscribe()
		);
	}
}
