import { Component } from '@angular/core';
import { menus, Menus } from '../../routes';
import { environment } from '../../../environments/environment.prod';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-drop-menu',
	templateUrl: './drop-menu.component.html',
	styleUrls: ['./drop-menu.component.scss']
})
export class DropdownMenuComponent {
	menus: Menus[] = menus;
	activeMenu: Menus;
	toggleTop = false;

	routerLink = '';

	ariaExpanded = false;
	public localName = environment.localName;

	constructor(public translate: TranslateService) {}

	toggleMenu(menu: Menus) {
		if (menu.visible) {
			menu.visible = false;
			this.ariaExpanded = false;
		} else {
			menu.visible = true;
			this.ariaExpanded = true;
			this.getRoute(menu);
		}
	}

	hideMenu(menu?: Menus) {
		if (!menu) {
			menus.forEach(item => {
				item.visible = false;
			});
			return;
		}

		menu.visible = false;
		this.ariaExpanded = false;
	}

	getRoute(menu: Menus) {
		this.routerLink = this.routerLink + '/' + menu.path;
	}
}
