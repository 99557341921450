import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { environment } from '@env';
import { LoginUtils } from '@services/loginutils/loginutils.service';

@Injectable({
	providedIn: 'root'
})
export class NavigationService {
	private isRedirectAfterClientLogout: boolean;

	constructor(
		private readonly activatedRoute: ActivatedRoute,
		private readonly location: Location,
		private readonly router: Router,
		private readonly loginUtils: LoginUtils
	) {
		this.isRedirectAfterClientLogout = this.activatedRoute.snapshot.queryParams.continue;
	}

	navigateBack(): void {
		if (this.isRedirectAfterClientLogout) {
			this.router.navigateByUrl('/applications');
		} else {
			this.location.back();
		}
		this.isRedirectAfterClientLogout = false;
	}

	createLinkToEditUserApplication(userEmail: string, userId: number): string {
		const domain = environment.clientUrl;
		const admin = encodeURIComponent(this.loginUtils.user.email);
		const user = encodeURIComponent(userEmail);
		const urlToEncode = `${window.location.href}?continue=true`;
		const redirectUrl = encodeURIComponent(urlToEncode);
		return `${domain}/login?admin=${admin}&user=${user}&userId=${userId}&logout=${redirectUrl}`;
	}
}
