export const environment = {
	version: '1.6.0',
	appName: 'OIAP admin',
	localName: 'OIAP admin',
	slugName: 'OIAP admin',
	production: true,
	apiUrl: 'https://admin.visanation.com/admin-api',
	downloadEmailsUrl: '/admin-api/emails',
	storagePrefix: 'OAP',
	language: 'en',
	availableLangs: ['en'],
	predefToken: null,
	keepStates: true,
	authorizationHeaderName: 'Authorization',
	storageTokenLocation: 'user.accessToken',
	routeWhiteList: ['/login', '/password/reset', '/403'],
	logLevel: 'DEBUG',
	clientUrl: 'https://app.visanation.com'
};
