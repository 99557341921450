import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-header-user',
	templateUrl: './app-header.component.html',
	styleUrls: ['./app-header.component.scss']
})
export class AppHeaderComponent {
	@Input() userName: string;
	@Input() userEmail: string;
	@Input() sponsorPhoneNumber: string;
	@Input() immigrantPhoneNumber: string;

	@Input() userId: number;
	@Input() applicationId?: number;
	@Input() chatThreadId?: number;
	@Input() status?: string;

	@Input() hasMenu = true;

	get messageLink() {
		return [
			'/messages',
			'thread',
			this.chatThreadId,
			'application',
			this.applicationId
		];
	}
}
