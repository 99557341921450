import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from './login.service';
import { LoginUtils } from '../../../_services/loginutils/loginutils.service';
import { TranslateService } from '@ngx-translate/core';
import { AutoUnsubscribe } from '../../../_decorators/autounsub';
import { LogInit } from '../../../_decorators/loginit';
import { environment } from '../../../../environments/environment';
import { LoggerService } from '@services/logger/logger.service';
import { UtilsService } from '@services/utils/utils.service';
import { ModalService } from '@services/utils/modal.service';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
@AutoUnsubscribe()
@LogInit()
export class LoginComponent implements OnInit {
	loginForm: FormGroup;
	passwordRecoveryForm: FormGroup;
	resetForm: FormGroup;
	pending = false;
	errors = null;
	loginError;
	passwordRecovery = false;
	passwordReset = false;
	public langs = environment.availableLangs;
	public get currentLang() {
		return this.translate.currentLang;
	}

	public submitReset$;
	public submitLogin$;
	public submitRecovery$;

	constructor(
		private fb: FormBuilder,
		private service: LoginService,
		private log: LoggerService,
		private utilsService: UtilsService,
		private modalService: ModalService,
		private loginutils: LoginUtils,
		private translate: TranslateService,
		private route: ActivatedRoute,
		private router: Router
	) {
		this.loginForm = this.fb.group({
			username: [null, Validators.required],
			password: [null, Validators.required]
		});

		this.passwordRecoveryForm = this.fb.group({
			email: [null, [Validators.required, this.utilsService.emailValidator]]
		});

		this.resetForm = this.fb.group({
			newPassword: [null, Validators.required],
			confirmPassword: [
				null,
				[
					Validators.required,
					this.utilsService.matchOtherValidator('newPassword')
				]
			],
			token: [null, Validators.required]
		});
	}

	async ngOnInit() {
		this.log.debug('Pages / LoginComponent', this);
		if (this.loginutils.isLogged) this.router.navigate(['/applications']);
		if (this.route.snapshot.params.token) {
			await this.initReset();
			this.openForgotPasswordModal(true);
		}
	}

	changeLang(lang) {
		this.loginutils.useLang(lang);
	}

	initReset() {
		this.passwordReset = true;
		this.resetForm.patchValue({
			token: this.route.snapshot.params.token
		});
	}

	submit($event) {
		$event.preventDefault();
		const { username, password } = this.loginForm.value;
		this.pending = true;
		this.submitLogin$ = this.service
			.login(username, password)
			.subscribe(res => this.loginOk(res), err => this.loginErr(err));
	}

	loginOk(res) {
		this.loginutils.user = res.user;
		this.router.navigate(['/applications']);
	}

	loginErr(err) {
		this.loginError = 'login.badLoginOrPass';
		this.pending = false;
	}

	openForgotPasswordModal(hasToken = false) {
		const options: NgbModalOptions = { windowClass: 'forgot-password-modal' };
		this.modalService.open(
			ForgotPasswordComponent,
			hasToken ? this.resetForm : this.passwordRecoveryForm,
			options
		);
	}
}
