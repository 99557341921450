import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-form-text-field',
	templateUrl: './form-text-field.component.html'
	// styleUrls: ['./form-text-field.component.scss']
})
export class FormTextFieldComponent implements OnInit {
	constructor() {}

	ngOnInit() {}
}
