import {
	AfterViewInit,
	ChangeDetectorRef,
	Directive,
	ElementRef
} from '@angular/core';

@Directive({
	selector: '[appAutofocus]'
})
export class AutofocusDirective implements AfterViewInit {
	constructor(
		private readonly elementRef: ElementRef,
		private readonly changeDetectorRef: ChangeDetectorRef
	) {}

	ngAfterViewInit(): void {
		this.elementRef.nativeElement.focus();
		this.changeDetectorRef.detectChanges();
	}
}
