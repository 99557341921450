import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NewMessageTemplateDto } from '@models/chatDto';

@Component({
	selector: 'app-message-template-create',
	templateUrl: './create-message-template.component.html'
})
export class CreateMessageTemplateComponent implements OnInit {
	@Input() data: any;

	message = '';

	constructor(private readonly activeModal: NgbActiveModal) {}

	ngOnInit() {}

	submit() {
		const body: NewMessageTemplateDto = {
			template: this.message
		};

		this.activeModal.close(body);
	}

	dismissModal() {
		this.activeModal.dismiss();
	}
}
