import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { LoginUtils } from './_services/loginutils/loginutils.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';
import { Storage } from './_services/storage/storage.service';
import { LoggerService } from '@services/logger/logger.service';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';

@Component({
	selector: 'app-root',
	template: `
		<ng-progress></ng-progress>
		<router-outlet></router-outlet>
	`
})
export class AppComponent implements OnInit {
	title = 'app';
	appRouter$;

	constructor(
		private router: Router,
		private log: LoggerService,
		private loginUtils: LoginUtils,
		private translate: TranslateService,
		private storage: Storage,
		private matIconRegistry: MatIconRegistry,
		private domSanitizer: DomSanitizer
	) {}

	ngOnInit() {
		this.appRouter$ = this.router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				this.log.debug(event.url);
			}
		});
		this.log.debug('AppComponent', this, this.loginUtils.user);
		this.checkLang();
		this.matIconRegistry.addSvgIcon(
			`icon-send`,
			this.domSanitizer.bypassSecurityTrustResourceUrl(
				'assets/img/icon-send.svg'
			)
		);
	}

	checkLang() {
		this.translate.addLangs(environment.availableLangs);
		this.translate.setDefaultLang(environment.language);

		this.storage.get('lang').subscribe(res => this.loginUtils.useLang(res));
	}
}
