import { BrowserModule } from '@angular/platform-browser';
import { Injector, LOCALE_ID, NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { SharedModule } from './_modules/shared/shared.module';
import { LayoutComponent } from './_components/layout/layout.component';
import { RoutesModule } from './_modules/routes/routes.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';

import { CoreModule } from './_modules/core/core.module';
import { SidebarComponent } from './_components/sidebar/sidebar.component';
import { registerLocaleData } from '@angular/common';
import localePl from '@angular/common/locales/pl';
import { NgProgressModule } from '@ngx-progressbar/core';
import { NgProgressHttpModule } from '@ngx-progressbar/http';
import { MatListModule } from '@angular/material/list';
import { ChangePasswordComponent } from './routes/account/change-password/change-password.component';

export function createTranslateLoader(http: HttpClient) {
	return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
	declarations: [
		AppComponent,
		LayoutComponent,
		SidebarComponent,
		ChangePasswordComponent
	],
	entryComponents: [ChangePasswordComponent],
	imports: [
		BrowserModule,
		HttpClientModule,
		SharedModule.forRoot(),
		RoutesModule,
		NgProgressModule,
		NgProgressHttpModule,
		BrowserAnimationsModule,
		CoreModule.forRoot(),
		ToastrModule.forRoot({
			timeOut: 3000,
			positionClass: 'toast-bottom-right'
		}),
		NgbModule.forRoot(),
		// StorageModule.forRoot(),
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: createTranslateLoader,
				deps: [HttpClient]
			}
		}),
		MatListModule
	],
	providers: [{ provide: LOCALE_ID, useValue: 'pl' }],
	bootstrap: [AppComponent]
})
export class AppModule {
	static injector: Injector;
	constructor(injector: Injector) {
		AppModule.injector = injector;
	}
}

registerLocaleData(localePl, 'pl');
